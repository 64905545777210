import React, { ReactElement, FC, useState } from "react"
import moment from "moment"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
// import { usePosts } from "../hooks/usePosts"
// import { useJsonPosts } from "../hooks/useJsonPosts"
import { useWordpressPosts } from "../hooks/useWordpressPosts"
import { Post } from "../models/Post"
import PostList from "../components/PostList"
// import GatsbyPagination from "../components/GatsbyPagination"

const IndexPage: FC<{ pageContext }> = ({ pageContext }) => {
  // const jsonPosts = useJsonPosts()
  const wordpressPosts = useWordpressPosts(["projekty"])
  const posts = wordpressPosts.sort((a: Post, b: Post) =>
    moment(a.date) < moment(b.date) ? 1 : -1
  )
  const title = "Projekty"
  return (
    <Layout>
      <Seo title={title} />
      <h2 className="pb-2 text-3xl font-bold">{title}</h2>
      <PostList posts={posts} pageContext={pageContext} />
    </Layout>
  )
}

export default IndexPage
